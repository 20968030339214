import styled from 'styled-components';

export const Error = styled.div`
  margin-top: 4px;
  color: red;
`;

export const FieldInput = styled.div`
  --react-international-phone-text-color: #fff;

  input {
    &::placeholder {
      color: white;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--auth-forms__input-bg, #bbb) inset;
      transition: background-color 50000s ease-in-out 0s;
      -webkit-text-fill-color: var(--auth-forms__input-color, #fff);
    }
  }
  .react-international-phone-input-container {
    border-radius: 50%;
    height: var(--auth-forms__input-height);

    .react-international-phone-input,
    .react-international-phone-country-selector {
      height: var(--auth-forms__input-height);
      border: 0;
      margin: 0;
      > button {
        padding-left: 7px;
        height: var(--auth-forms__input-height);
        background: rgba(255, 255, 255, 0.15);
        border: 1px solid rgba(255, 255, 255, 0.15);
        border-radius: 5px 0 0 5px;
      }
    }
    .react-international-phone-input {
      border-radius: 0 5px 5px 0;
    }
    .react-international-phone-country-selector {
      border-radius: 5px 0 0 5px;
    }
  }
`;

export const Loader = styled.div`
  height: ${(props) => (props.height ? props.height : 'inherit')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SocialLoginButton = styled.div``;

export const BlockButton = styled.div``;
