import React, { useLayoutEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SupportButtons } from '../../components/SupportButtons/SupportButtons';

import HelmetComponent from './Helmet';

const Page = ({ children, url = '' }) => {
  const searchResult = useSelector((state) => state.searchResult);

  const location = useLocation();

  useLayoutEffect(() => {
    const ignorePage = ['provider', 'tag', 'category', 'games'];
    const path = location.pathname;

    const shouldScrollToTop = !ignorePage.some((page) => path.includes(page));

    if (shouldScrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const viewFirstWord = (location) => {
    const pathname = location?.pathname;
    const firstWord = pathname?.split('/')[1];

    if (firstWord === undefined) return [];
    return ' ' + firstWord;
  };

  const firstWord = viewFirstWord(url);

  return (
    <Fragment>
      <HelmetComponent />
      <div className={`view${firstWord}`}>{!Boolean(searchResult?.query) && children}</div>

      <SupportButtons />
    </Fragment>
  );
};

Page.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    content: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  navigate: PropTypes.shape({
    header: PropTypes.bool,
    menu: PropTypes.bool,
    filter: PropTypes.bool,
    footer: PropTypes.bool,
  }),
};

export default Page;
