import { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Cookies from 'js-cookie';
import ReactInlineSvg from 'react-inlinesvg';
import classNames from 'classnames';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { defaultCountries } from 'react-international-phone';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Tab, ButtonTypes, ButtonsEl } from '../../../elements';
import { AnimatedPreloader, Preloader } from '../../../components';

import { getYourIpRequest, setActiveForm, signUpRequest, signUpWhatsAppRequest } from '../../../store/actions';
import useQuery from '../../../hooks/useQuery';

import { Error, FieldInput, BlockButton, SocialLoginButton, Loader } from '../assets/FormStyles';
import { useIpUser } from '../../../hooks';
import { registrationSchema } from '../../../schemas/registrationSchema';
import { ControllerForm } from '../../../elements';
import { translateField } from '../../../shared/utils';
import { SOCIAL_AUTH } from '../SocialAuth';
import { AcceptTerms } from '../../../elements/AcceptTerms';

export const SignUpForm = () => {
  const dispatch = useDispatch();

  const words = useSelector((state) => state.words);

  const domain = window.location.hostname;
  const query = useQuery();

  const { ipInfo } = useIpUser();

  const key = query.get('key');
  const data = useSelector((state) => state.api);
  const values = useSelector((state) => state.api?.registration?.values);

  const googleCaptchaToken = useSelector((state) => state.api?.modules?.googleCaptcha);
  const { executeRecaptcha } = useGoogleReCaptcha(); // Всегда вызываем хук

  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const handleChangeTerms = useCallback(() => {
    setTerms((prevTerms) => !prevTerms);
  }, [setTerms]);

  const [visitorId, setVisitorId] = useState('');

  const infoRegister = (localStorage.getItem('registeredDeviceId') || Cookies.get('registeredDeviceId')) === visitorId;

  const registerDevice = useCallback(() => {
    localStorage.setItem('registeredDeviceId', visitorId);
    Cookies.set('registeredDeviceId', visitorId, { expires: 365 });
  }, [visitorId]);

  useEffect(() => {
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const deviceId = result.visitorId;
      setVisitorId(deviceId);
    };

    getFingerprint();
  }, []);

  const {
    control,
    watch,
    formState: { errors },
    reset,
    getValues,
    handleSubmit,
  } = useForm({ resolver: yupResolver(registrationSchema), context: { values }, mode: data?.registration?.type === 'emaiOrPhone' ? 'onChange' : 'onSubmit' });
  const watchAllFields = watch();

  const signUpError = useSelector((state) => state.auth.signUpError);
  const navigate = useNavigate();
  const [preloader, setPreloader] = useState(false);
  const [form, setForm] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [type, setType] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const socialAuthData = useSelector((state) => state.api.modules);
  const promoCode = localStorage.getItem('promoCode');

  const handleChange = useCallback(
    (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form],
  );

  const handleSignUp = useCallback(async () => {
    if (!terms) {
      setTermsError(true); // Set the terms error to true if not checked
      setPreloader(false);
      return; // Do not proceed with form submission
    }

    if (infoRegister) {
      dispatch({
        type: 'SIGNUP_TYPE_ERROR',
        payload: 'registration_is_not_available_on_this_device',
      });

      setTimeout(() => {
        dispatch({
          type: 'SIGNUP_TYPE_ERROR',
          payload: undefined,
        });
      }, 3000);
      return;
    }

    setPreloader(true);
    await dispatch(getYourIpRequest());

    // Execute reCAPTCHA
    let recaptchaToken = ''; // Initialize reCAPTCHA token variable

    // Check if googleCaptchaToken exists before executing reCAPTCHA
    if (Boolean(googleCaptchaToken)) {
      recaptchaToken = await executeRecaptcha('submit');
      if (!recaptchaToken) {
        // Handle case where reCAPTCHA token is not available
        setPreloader(false);
        return;
      }
    }

    const formWithPromoCode = { ...form, ...getValues(), promoCode };
    const formDefault = { ...form, ...getValues() };

    if (!infoRegister)
      await dispatch(
        signUpRequest({
          form: promoCode ? formWithPromoCode : formDefault,
          domain: domain,
          recaptchaToken: Boolean(googleCaptchaToken) && recaptchaToken ? recaptchaToken : '',
        }),
      ).then((res) => {
        if (!res.error) {
          registerDevice();
        }
        setPreloader(false);
        if (res.content.token) {
          localStorage.removeItem('promoCode');
          navigate('/');
          reset();
        }
        if (!res.error) dispatch(setActiveForm('success'));
      });
  }, [dispatch, getValues, domain, setTermsError, terms, navigate, reset, setPreloader, form, executeRecaptcha, googleCaptchaToken, promoCode, registerDevice, infoRegister]);

  const getFormVal = useCallback(
    (type, value) => {
      setError(false);
      setForm({ ...form, [type]: value });
    },
    [form],
  );

  const codeChange = useCallback(
    (isvalid, value) => {
      setError(false);
      getFormVal('dialCode', value.dialCode);
      setForm({ ...form, phone: value });
    },
    [getFormVal, form],
  );

  const numberChange = useCallback(
    (isvalid, value, event) => {
      setError(false);
      getFormVal('phone', event.dialCode + value);
      let type = value && value.length > 0 && value !== '' ? 'phone' : false;
      setType(type);
    },
    [getFormVal],
  );
  const handleBack = () => {
    dispatch(setActiveForm('login'));
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
    reset();
  };

  useEffect(() => {
    reset();
    setTerms(false);

    return () => {
      reset();
      setTerms(false);
    };
  }, [reset, activeTab]);

  const formBlock = useMemo(() => {
    return data.registration?.values
      .map((item) => {
        switch (item.id) {
          case 'phone':
            if ((activeTab === 1 && data?.registration?.type === 'emaiOrPhone') || data?.registration?.type === 'selected') {
              return (
                <FieldInput className="auth-form_field" key={item.id}>
                  <ControllerForm
                    required={item.require !== '0'}
                    placeholder={translateField(item.id, 'auth.input', words, false)}
                    defaultCountry={ipInfo?.country?.toLowerCase() || 'us'}
                    DialCodePreview={ipInfo?.country}
                    forceDialCode
                    fieldType={'phoneInput'}
                    control={control}
                    errors={errors}
                    name={item.id}
                    onChange={numberChange}
                    onChangeCountry={codeChange}
                  />
                </FieldInput>
              );
            } else return null;
          case 'email':
            if ((activeTab === 0 && data?.registration?.type === 'emaiOrPhone') || data?.registration?.type === 'selected') {
              return (
                <FieldInput className="auth-form_field" key={item.id}>
                  <ControllerForm
                    required={item.require !== '0'}
                    placeholder={translateField(item.id, 'auth.input', words, false)}
                    className="InputPassword"
                    classNameInput="input"
                    control={control}
                    errors={errors}
                    name={item.id}
                  />
                </FieldInput>
              );
            } else return null;
          case 'name':
            return (
              <FieldInput className="auth-form_field" key={item.id}>
                <ControllerForm
                  required={item.require !== '0'}
                  placeholder={translateField(item.id, 'auth.input', words, false)}
                  className="InputPassword"
                  classNameInput="input"
                  control={control}
                  errors={errors}
                  onChange={handleChange}
                  name={item.id}
                />
              </FieldInput>
            );
          case 'password':
            return (
              <FieldInput className="auth-form_field" key={item.id}>
                <ControllerForm
                  placeholder={translateField(item.id, 'auth.input', words, false)}
                  required={item.require !== '0'}
                  className="InputPassword"
                  classNameInput="input"
                  control={control}
                  errors={errors}
                  name={item.id}
                  fieldType="inputPassword"
                />
              </FieldInput>
            );
          case 'promoCode':
            return (
              !key && (
                <FieldInput
                  className={classNames('auth-form_field', {
                    hidden: promoCode,
                  })}
                  key={item.id}
                >
                  <ControllerForm
                    defaultValue={promoCode ? promoCode : null}
                    required={item.require !== '0' && promoCode ? false : true}
                    placeholder={`${translateField('promo_code', 'auth.input', words, false)} ${item.require !== '0' ? '(Required)' : '(Optional)'} `}
                    className="InputPassword"
                    classNameInput="input"
                    control={control}
                    errors={promoCode ? null : errors}
                    name={item.id}
                    signUpError={signUpError}
                  />
                </FieldInput>
              )
            );
          case 'currency':
            return (
              <FieldInput className="auth-form_field" key={item.id}>
                <ControllerForm
                  required={item.require !== '0'}
                  placeholder={translateField(item.id, 'auth.input', words, false)}
                  fieldType="select"
                  selectOptions={item.options}
                  className="InputPassword"
                  control={control}
                  errors={errors}
                  name={item.id}
                />
              </FieldInput>
            );
          default:
            return (
              <FieldInput className="auth-form_field" key={item.id}>
                <ControllerForm
                  required={item.require !== '0'}
                  placeholder={translateField(item.id, 'auth.input', words, false)}
                  className="InputPassword"
                  classNameInput="input"
                  control={control}
                  errors={errors}
                  onChange={handleChange}
                  name={item.id}
                />
                {signUpError && <Error className="error">{signUpError[item.id]?.split('_').join(' ')}</Error>}
              </FieldInput>
            );
        }
      })
      .filter((el) => el !== null);
  }, [codeChange, control, data?.registration?.values, errors, key, numberChange, signUpError, ipInfo, handleChange, words, activeTab, data?.registration?.type, promoCode]);

  // console.log(formBlock.map((el) => el.key));

  const validatePhone = useCallback((value) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const isPhoneValid = (phone) => {
      try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
      } catch (error) {
        return false;
      }
    };

    const listCode = defaultCountries.map((item) => item[2]);
    const hasCountryCodeOnly = listCode.some((code) => value === `+${code}`); // Only the country code
    const hasValidCountryCode = value && value.startsWith('+') && listCode.some((code) => value.startsWith(`+${code}`)); // Country code with additional characters

    if (!value) {
      return false;
    } else if (hasCountryCodeOnly) {
      return false;
    } else if (!hasValidCountryCode) {
      return false;
    } else if (!isPhoneValid(value)) {
      return false;
    } else {
      return true;
    }
  }, []);

  const renderFormContent = useMemo(() => {
    const hasPhoneField = formBlock?.some((el) => el.key === 'phone');
    const phoneValue = watchAllFields.phone;
    const isPhoneValid = validatePhone(phoneValue);

    return (
      <div className="auth-form_wrapper">
        <form onSubmit={handleSubmit(handleSignUp)}>
          {formBlock}
          <AcceptTerms onChange={handleChangeTerms} error={!terms && termsError} termsChecked={terms} />

          <BlockButton className="auth-form_buttons">
            <ButtonsEl
              type="submit"
              disabled={!Object.values(watchAllFields).some((item) => !!item) || !terms || (hasPhoneField && !isPhoneValid)}
              className={
                !Object.values(watchAllFields).some((item) => !!item) || !terms || (hasPhoneField && !isPhoneValid) ? 'submit-button submit-button-disabled' : 'submit-button'
              }
              // onClick={data?.registration?.type === 'emaiOrPhone' && activeTab === 1 ? handleSignUp : null}
            >
              {translateField('registration', 'auth.button', words, false)}
            </ButtonsEl>

            {!!data.registration?.social?.some((item) => Object.keys(SOCIAL_AUTH).includes(item)) && (
              <>
                <div className="auth-form_social_title">{translateField('or_can_registration', 'auth.button', words, false)}</div>
                <SocialLoginButton className="auth-form_social_buttons">
                  {data.registration.social.map((item) => {
                    const SocialAuth = SOCIAL_AUTH?.[item];
                    return SocialAuth ? (
                      <SocialAuth
                        key={item}
                        setPreloader={setPreloader}
                        authId={socialAuthData[`auth.${item}`]}
                        clickHandler={item === 'whatsApp' ? () => setActiveTab(2) : undefined}
                      />
                    ) : null;
                  })}
                </SocialLoginButton>
              </>
            )}
          </BlockButton>
        </form>
        {signUpError && (
          <>
            {typeof signUpError === 'string' ? (
              <Error className="error">{translateField(signUpError, 'auth.error', words, false)}</Error>
            ) : (
              Object.keys(signUpError).map((errorKey) => {
                const registrationValueIds = data.registration.values.map((value) => value.id);
                const shouldDisplayError = registrationValueIds.includes(errorKey);
                const errorMessage = signUpError[errorKey];

                return shouldDisplayError ? (
                  <Error key={errorKey} className="error">
                    {errorKey} {translateField(errorMessage, 'auth.error', words, false)}
                  </Error>
                ) : null;
              })
            )}
          </>
        )}
      </div>
    );
  }, [data, formBlock, handleSignUp, handleSubmit, handleChangeTerms, terms, termsError, signUpError, socialAuthData, watchAllFields, words, validatePhone]);

  const isTimerReady = localStorage.getItem('resendCodeTimer') === 'true';
  const [isCodeSent, setIsCodeSent] = useState(isTimerReady);

  const SECONDS_WAIT_BEFEORE_RESEND_CODE = 30;
  const [timerSec, setTimerSec] = useState(SECONDS_WAIT_BEFEORE_RESEND_CODE);
  const [isWhatsAppSubmitEnabled, setIsWhatsAppSubmitEnabled] = useState(false);
  const handleWhatsAppSignUp = useCallback(async () => {
    setPreloader(true);
    setIsCodeSent(true);
    setTimerSec(SECONDS_WAIT_BEFEORE_RESEND_CODE);
    await dispatch(getYourIpRequest());

    if (!terms) {
      setTermsError(true); // Set the terms error to true if not checked
      setPreloader(false);
      return; // Do not proceed with form submission
    }

    // Execute reCAPTCHA
    let recaptchaToken = ''; // Initialize reCAPTCHA token variable

    // Check if googleCaptchaToken exists before executing reCAPTCHA
    if (Boolean(googleCaptchaToken)) {
      recaptchaToken = await executeRecaptcha('submit');
      if (!recaptchaToken) {
        // Handle case where reCAPTCHA token is not available
        setPreloader(false);
        return;
      }
    }

    const social = {
      network: 'whatsApp',
      phone: getValues('phone'),
    };

    const code = getValues('code');
    if (code) social.code = code;

    await dispatch(
      signUpWhatsAppRequest({
        domain: domain,
        social,
      }),
    ).then((res) => {
      setPreloader(false);
      if (!res?.error) setIsWhatsAppSubmitEnabled(true);
      if (!code) {
        localStorage.setItem('resendCodeTimer', true);
      } else {
        localStorage.removeItem('resendCodeTimer');
      }

      if (res?.content?.token) {
        localStorage.removeItem('promoCode');
        navigate('/');
        reset();
      }
    });
  }, [dispatch, getValues, domain, navigate, reset, setPreloader, setTermsError, terms, executeRecaptcha, googleCaptchaToken]);

  useEffect(() => {
    if (!isCodeSent) return;
    const ONE_SECOND = 1000;

    const timerId = setTimeout(() => {
      setTimerSec((prev) => prev - 1);
      if (timerSec === 1) {
        setIsCodeSent(false);
        localStorage.removeItem('resendCodeTimer');
      }
    }, ONE_SECOND);

    return () => {
      clearTimeout(timerId);
    };
  }, [isCodeSent, timerSec]);

  const renderWhatsappFormContent = useMemo(() => {
    const codeIsReady = getValues('code')?.length >= 4;
    const allFieldsAreFilled = Object.values(watchAllFields).some((item) => !!item);
    const isConfirButtonEnabled = codeIsReady && allFieldsAreFilled;
    const withWhatsApp = data.registration?.social.find((x) => x === 'whatsApp');

    return (
      <div className="auth-form_wrapper whatsapp-auth-form_wrapper">
        {!!data.registration?.social?.some((item) => Object.keys(SOCIAL_AUTH).includes(item)) && (
          <>
            <SocialLoginButton className="auth-form_social_tab">
              {data.registration.social.map((item) => {
                const SocialAuth = SOCIAL_AUTH?.[item];
                return SocialAuth ? (
                  <SocialAuth
                    key={item}
                    setPreloader={setPreloader}
                    authId={socialAuthData[`auth.${item}`]}
                    clickHandler={item === 'whatsApp' ? () => setActiveTab(2) : undefined}
                  />
                ) : null;
              })}
            </SocialLoginButton>
          </>
        )}
        {withWhatsApp && (
          <form onSubmit={handleSubmit(handleWhatsAppSignUp)}>
            <FieldInput className="auth-form_field">
              <ControllerForm
                required={true}
                placeholder={translateField('phone', 'auth.input', words, false)}
                defaultCountry={ipInfo?.country?.toLowerCase() || 'us'}
                DialCodePreview={ipInfo?.country}
                forceDialCode
                fieldType={'phoneInput'}
                control={control}
                errors={errors}
                name={'phone'}
                onChange={numberChange}
                onChangeCountry={codeChange}
              />
              <AcceptTerms onChange={handleChangeTerms} error={!terms && termsError} termsChecked={terms} />

              <BlockButton className="auth-form_buttons">
                <ButtonsEl
                  type="submit"
                  disabled={isCodeSent || !Object.values(watchAllFields).some((item) => !!item) || !terms}
                  className={!isCodeSent && Object.values(watchAllFields).some((item) => !!item) && terms ? 'submit-button' : 'submit-button submit-button-disabled'}
                  onClick={handleWhatsAppSignUp}
                >
                  {translateField('send_code', 'auth.button', words, false)}
                </ButtonsEl>
              </BlockButton>
            </FieldInput>
            {isCodeSent && (
              <div className="resend-warning-message">
                {translateField('timer_wa', 'auth.message', words, false)} <span>{timerSec}</span>
              </div>
            )}
            {isWhatsAppSubmitEnabled && (
              <>
                <FieldInput className="auth-form_field mt30" key="whatsapp-code-submit">
                  <ControllerForm
                    required={true}
                    placeholder={translateField('code', 'auth.input', words, false)}
                    defaultCountry={ipInfo?.country?.toLowerCase() || 'us'}
                    DialCodePreview={ipInfo?.country}
                    forceDialCode
                    fieldType={'input'}
                    control={control}
                    errors={errors}
                    name={'code'}
                    onChange={numberChange}
                    onChangeCountry={codeChange}
                  />
                  <BlockButton className="auth-form_buttons">
                    <ButtonsEl
                      type="submit"
                      disabled={!isConfirButtonEnabled}
                      className={classNames('submit-button', { 'submit-button submit-button-disabled': !isConfirButtonEnabled })}
                      onClick={handleWhatsAppSignUp}
                    >
                      {translateField('confirm', 'auth.button', words, false)}
                    </ButtonsEl>
                  </BlockButton>
                </FieldInput>
              </>
            )}
          </form>
        )}
        {signUpError && (
          <>
            {typeof signUpError === 'string' ? (
              <Error className="error">{translateField(signUpError, 'auth.error', words, false)}</Error>
            ) : (
              Object.keys(signUpError).map((errorKey) => {
                const registrationValueIds = data.registration.values.map((value) => value.id);
                const shouldDisplayError = registrationValueIds.includes(errorKey);
                const errorMessage = signUpError[errorKey];

                return shouldDisplayError ? (
                  <Error key={errorKey} className="error">
                    {errorKey} {translateField(errorMessage, 'auth.error', words, false)}
                  </Error>
                ) : null;
              })
            )}
          </>
        )}
      </div>
    );
  }, [
    isCodeSent,
    timerSec,
    getValues,
    watchAllFields,
    handleSubmit,
    handleWhatsAppSignUp,
    words,
    ipInfo?.country,
    control,
    errors,
    numberChange,
    codeChange,
    isWhatsAppSubmitEnabled,
    signUpError,
    data.registration?.values,
    data.registration?.social,
    socialAuthData,
    handleChangeTerms,
    terms,
    termsError,
  ]);

  const reorderedTabs = [
    {
      id: 0,
      key: 'reg_from_email',
      name: translateField('signup_email', 'auth', words),
      comp: renderFormContent,
    },
    {
      id: 1,
      key: 'reg_from_phone',
      name: translateField('signup_phone', 'auth', words),
      comp: renderFormContent,
    },
  ];

  if (!!data?.registration?.social?.length) {
    const name = data?.registration?.social?.length === 1 ? data?.registration?.social[0] : translateField('social_tab', 'auth', words);

    reorderedTabs.push({
      id: 2,
      key: 'reg_from_whatsapp',
      name,
      comp: renderWhatsappFormContent,
    });
  }

  if (!ipInfo)
    return (
      <Loader className="auth-form loader-form">
        <AnimatedPreloader />
      </Loader>
    );

  return (
    <div className={classNames('auth-form', {})}>
      {preloader && <Preloader />}
      <div className="auth-form_title">{translateField('registration', 'auth', words, false)}</div>
      <div className={classNames('auth-form_content', {})}>
        <Tab
          tabs={reorderedTabs}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          className={classNames('auth-form-tabs', {
            'hide-nav': data?.registration?.type !== 'emaiOrPhone',
          })}
          navIsSlider={false}
        />
      </div>

      <div className="handle-back-in-form">
        <ButtonTypes styled="rounded" location={'back-history'} onClick={handleBack}>
          <ReactInlineSvg className="image arrow-left" src="/images/icons/arrow__left.svg" desciption="arrow" />
          <span>{translateField('back', 'basic', words)}</span>
        </ButtonTypes>
      </div>
    </div>
  );
};
