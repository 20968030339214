import { useRef, useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export const SupportButtons = () => {
  const { tawkMessenger = {} } = window.settings;
  const { propertyId, widgetId } = tawkMessenger;
  const tawkMessengerEnabled = propertyId && widgetId;
  const tawkMessengerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Проверяем, был ли клик за пределами компонента
      if (tawkMessengerRef?.current && !tawkMessengerRef.current?.contains?.(event.target)) {
        // Вызываем метод isChatMinimized(), если чат Tawk.to открыт
        if (tawkMessengerRef?.current && !tawkMessengerRef?.current?.isChatMinimized()) {
          tawkMessengerRef?.current?.minimize();
        }
      }
    };

    // Добавляем обработчик клика при монтировании компонента
    document.addEventListener('click', handleClickOutside);

    // Удаляем обработчик клика при размонтировании компонента
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [tawkMessengerRef]);

  const onLoad = () => {
    tawkMessengerRef.current?.minimize?.();
  };

  const onVisitorNameChanged = (visitorName) => {
    tawkMessengerRef.current.setVisitorName(visitorName);
  };

  if (!tawkMessengerEnabled) return null;

  return <TawkMessengerReact propertyId={propertyId} widgetId={widgetId} onLoad={onLoad} onVisitorNameChanged={onVisitorNameChanged} ref={tawkMessengerRef} />;
};
