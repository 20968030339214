import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Header from '../../components/_Header/Header';
import { translateFieldNew as translateField } from '../../shared/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
`;

const Container = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr) auto;
  gap: 10px;
  padding: 0 0 20px;

  @media (max-width: 992px) and (orientation: landscape) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr auto;
  }

  img {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    margin: auto;
  }
`;

const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > div {
    background-position: center right;
  }

  &:nth-child(2n) > div {
    background-position: center left;
  }
`;

const FooterButtons = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  --background: var(--home-banners-button-bg, var(--top-block-button_bg, #61ce70));
  background: var(--background);
  border-radius: 5px;
  padding: 8px 10px;
  color: var(--home-banners-button-color, #ffffff);
  font-size: 18px;
  font-family: 'Roboto', Sans-serif;
  text-align: center;
  width: 80%;
  max-width: 300px;
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    color: var(--home-banners-button-color_hover, #ffffff);
    background: var(--home-banners-button-bg_hover, var(--background));
  }
`;

const ResponsiveBg = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.image_pc})`};

  @media (max-width: 767px) {
    background-image: ${(props) => `url(${props.image})`};
  }
`;

const Modal = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;

  background-color: ${({ isOnlyForm }) => (isOnlyForm ? 'transparent' : 'var(--background-modal)')};
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;

  .fullform-section__link span {
    color: var(--auth-forms__section-links_color, #fff);
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`;

const ModalWrapper = styled.div`
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-height: 991px) {
    top: 5px;
    transform: translate(-50%, 0);
  }

  .show-form_content {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    overflow: hidden;
  }
`;

const Cross = styled.div`
  position: absolute;
  right: 6px;
  top: 5px;
  width: 24px;
  height: 24px;
  opacity: 1;
  z-index: 10;

  /* background: var(--auth-forms-bg); */

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    left: 11px;
    content: ' ';
    height: 23px;
    width: 2px;
    background-color: var(--cross-color, #fff);
    --local-cross-box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    box-shadow: var(--cross-box-shadow, var(--local-cross-box-shadow));
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media (min-width: 921px) {
    top: 5px;
  }
`;

const ModalDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  text-align: center;
  color: var(--home-banners-details-color, #ffffff);
  font-size: var(--home-banners-details-font-size, 16px);
  white-space: pre-wrap;
`;

const ModalTitle = styled.img`
  width: calc(100% + 2.7em);
  display: block;
  margin: -1.5em -1.35em 0;
`;

export const HomeBanners = memo(({ banners, setShowRegBlock }) => {
  const { format = 'webp', list = [], baseUrl = '/uploads/' } = banners;
  const language = useSelector((state) => state.api.language);
  const words = useSelector((state) => state.words);
  const buttonText = translateField('homepage_banners_button', words);
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState(null);
  const [bgTitle, setBgTitle] = useState(null);
  const [externalLink, setExternalLink] = useState(null);

  const closeHanlder = () => {
    setShowModal(false);
  };

  return (
    <>
      <Wrapper>
        <Header />
        <Container key="HomeBanners">
          {list.map((banner, idx) => {
            const { src, alt = '', link = null, skipDetails = false } = banner;
            const image = `${baseUrl}${src}_${language}.${format}`;
            const image_pc = `${baseUrl}${src}_pc_${language}.${format}`;
            const href = link && skipDetails ? link : null;

            return (
              <Link
                href={href}
                key={src}
                onClick={() => {
                  if (skipDetails) {
                    setExternalLink(null);
                    !link && setShowRegBlock(true);
                  } else {
                    setDetails(translateField(`homepage_banners_bonus_${idx}_details`, words));
                    setBgTitle(image_pc);
                    setShowModal(true);
                    setExternalLink(link);
                  }
                }}
              >
                <ResponsiveBg image_pc={image_pc} image={image} alt={alt} />
              </Link>
            );
          })}
          <FooterButtons className="auth-form_buttons">
            <Button onClick={() => setShowRegBlock(true)}>{buttonText}</Button>
          </FooterButtons>
        </Container>
      </Wrapper>
      {showModal && (
        <Modal>
          <ModalWrapper>
            <div className="show-form modal-form">
              <div className="show-form_content">
                <Cross onClick={closeHanlder} className="close" />
                <ModalTitle src={bgTitle} />
                <ModalDetails>{details}</ModalDetails>
                {externalLink && (
                  <FooterButtons>
                    <Button onClick={() => window.open(externalLink, '_blank')}>{translateField('homepage_banners_bonus_details_button', words)}</Button>
                  </FooterButtons>
                )}
              </div>
            </div>
          </ModalWrapper>
          <div className="backdrop" onClick={closeHanlder}></div>
        </Modal>
      )}
    </>
  );
});
