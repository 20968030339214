import styled from 'styled-components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import Games from '../GamesPage/Games';
import Bonuses from '../BonusesPage/Bonuses';
import Payments from '../PaymentsPage/Payments';
import Page from '../_Page/Page';

// import FullForm from '../_FullForm/FullForm';
import Header from '../../components/_Header/Header';
import Footer from '../../components/_Footer/Footer';
import FullForm from '../../components/_FullForm/FullForm';
import TopBlock from './TopBlock';
import { HomeBanners } from './HomeBanners';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  justify-content: center;
  align-items: center;

  @media (max-width: 776px) {
    margin-top: 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1140px;

  @media (max-width: 776px) {
    width: 100%;
    padding: 0 2.5%;
  }

  @media (max-width: 1139px) {
    width: 100%;
    padding: 0 15px;
  }
`;

const HomePage = () => {
  const [showRegBlock, setShowRegBlock] = useState(false);
  // const homeGamesList = useSelector((state) => state.settings?.homeGamesList) || [];
  const { homePage } = window.settings;

  useEffect(() => {
    const isFormEnabled = window?.settings?.is_form_open;
    const isFormClosedByUser = window?.settings?.save_closed_form_state ? localStorage.getItem('setOpenForm') === 'false' : false;
    const isOpen = isFormEnabled && !isFormClosedByUser;

    if (isOpen) {
      setShowRegBlock(true);
    }
  }, []);

  useEffect(() => {
    if (showRegBlock) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [showRegBlock]);

  return (
    <Page>
      <Container
        className={classNames('roulette-section', {
          'roulette-section--show-form': showRegBlock,
        })}
      >
        {homePage?.banners ? (
          <Wrapper>
            <HomeBanners key="HomeBanners" banners={homePage.banners} setShowRegBlock={setShowRegBlock} />
          </Wrapper>
        ) : (
          <>
            <Wrapper>
              <Header />
              <TopBlock key="HomeTopBlock" setShowRegBlock={setShowRegBlock} />
              <Games key="HomeGames" setShowRegBlock={setShowRegBlock} />
              <Bonuses setShowRegBlock={setShowRegBlock} />
              <Payments />
            </Wrapper>
            <Footer />
          </>
        )}
        {showRegBlock && <FullForm setShowRegBlock={setShowRegBlock} />}
      </Container>
    </Page>
  );
};

export default HomePage;
