import styled from 'styled-components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { translateField } from '../../shared/utils';

const Container = styled.header`
  position: relative;
  height: var(--header-heigth, 66px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

const Logo = styled.img`
  width: 130px;
`;

const Links = styled.div`
  display: flex;

  @media (max-width: 776px) {
    position: absolute;
    top: 66px;
    width: 100%;
    min-height: 40px;
    padding-top: 10px;
    background: var(--menu-burger-bg, #1e1717);

    display: none;
    flex-direction: column;

    &.opened {
      display: flex;
    }
  }
`;

const Link = styled.a`
  color: #ffffff;
  color: var(--menu-link-color, #fff);
  font-size: 16px;
  font-weight: bold;
  margin-right: 30px;

  &.active {
    color: var(--menu-link-color_active, #18a189);
  }

  &:last-child {
    margin-right: 0;
  }

  &:active,
  &:hover {
    color: var(--menu-link-color_hover, #ff0000);
  }

  @media (max-width: 776px) {
    margin-bottom: 25px;
  }
`;

const Icon = styled.div`
  color: var(--menu-burger-icon-color, #ffffff);

  @media (max-width: 776px) {
    display: block;
  }

  display: none;
`;

const OpenClose = ({ isOpen, setIsOpen }) => {
  return (
    <Icon onClick={() => setIsOpen((prev) => !prev)}>
      {isOpen ? (
        <svg
          height={25}
          width={25}
          aria-hidden="true"
          tabindex="0"
          className="e-font-icon-svg e-far-window-close"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v340zM356.5 194.6L295.1 256l61.4 61.4c4.6 4.6 4.6 12.1 0 16.8l-22.3 22.3c-4.6 4.6-12.1 4.6-16.8 0L256 295.1l-61.4 61.4c-4.6 4.6-12.1 4.6-16.8 0l-22.3-22.3c-4.6-4.6-4.6-12.1 0-16.8l61.4-61.4-61.4-61.4c-4.6-4.6-4.6-12.1 0-16.8l22.3-22.3c4.6-4.6 12.1-4.6 16.8 0l61.4 61.4 61.4-61.4c4.6-4.6 12.1-4.6 16.8 0l22.3 22.3c4.7 4.6 4.7 12.1 0 16.8z"></path>
        </svg>
      ) : (
        <svg
          height={25}
          width={25}
          aria-hidden="true"
          tabindex="0"
          className="e-font-icon-svg e-fas-align-justify"
          viewBox="0 0 448 512"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path d="M432 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path>
        </svg>
      )}
    </Icon>
  );
};

const Header = () => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const words = useSelector((state) => state.words);
  const headerMenu = useSelector((state) => state.settings?.headerMenu) || [];

  return (
    <Container>
      <a href="/">
        <Logo src="/images/landing/logo-1.png" />
      </a>
      {headerMenu.length > 0 && (
        <>
          <Links className={isOpen ? 'opened' : ''}>
            {headerMenu.map((item) => (
              <Link className={item.link === pathname ? 'active' : ''} href={item.link} key={item.link}>
                {translateField(item.title, 'header', words)}
              </Link>
            ))}
          </Links>

          <OpenClose setIsOpen={setIsOpen} isOpen={isOpen} />
        </>
      )}
    </Container>
  );
};

export default Header;
